import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import {
	Checkbox,
	Chip,
	IconButton,
	Menu,
	MenuItem,
	Select,
	Tooltip
} from '@mui/material'
import {
	MoreVert,
	EditOutlined,
	DeleteOutlineOutlined,
	AddOutlined,
	BlockOutlined,
	MailOutline,
	AlternateEmailOutlined,
	DoneRounded,
	HelpOutlineOutlined
} from '@mui/icons-material'

import ButtonComponent from '../components/shared/ButtonComponent'
import PageHeader from '../components/shared/PageHeader'
import GenericTable from '../components/shared/GenericTable/GenericTable'
import { PrintOutlined } from '@mui/icons-material'
import { getChecks, printStyles } from '../utils/helper'
import { updateSnackbar } from '../redux/snackbarState'
import { deleteCheck, updateCheck } from '../API/ChecksAPI'

import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router'
import FormModalMUI from '../components/shared/Modals/FormModalMUI'
import Printcheck from '../components/views/Printcheck'
import { useReactToPrint } from 'react-to-print'
import MailChecks from '../components/views/forms/MailChecks'
import EmailChecks from '../components/views/forms/EmailChecks'
import CheckStatusPopover from '../components/shared/Popovers/CheckStatusPopover'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min'

const ContextMenu = ({ rowData, setPrintContent, status }) => {
	const [anchorEl, setAnchorEl] = React.useState(null)
	const open = Boolean(anchorEl)

	const history = useHistory()

	const [isModalOpen, setModalOpen] = useState(false)

	const [deleteCheckNumber, setDeleteCheckNumber] = useState('')
	const [deleteCheckStatus, setDeleteCheckStatus] = useState('')
	const [deleteCheckId, setDeleteCheckId] = useState('')
	const organizationData = useSelector((state) => state.appData.organizations)
	const [organizationData1, setOrgizationData] = useState()
	const selectedOrganization = useSelector(
		(state) => state.appData.selectedOrganization
	)
	// console.log(selectedOrganization,"Organization Data")

	useEffect(() => {
        let result = organizationData.data.filter((data) => {
            return data?._id === selectedOrganization
        })

        if(result.length > 0) {
			setOrgizationData(result[0]);
        } else {
			setOrgizationData();
        }
    }, [organizationData, selectedOrganization]);


	const openModal = () => {
		setModalOpen(true)
	}

	const closeModal = () => {
		setModalOpen(false)
	}

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget)
		event.stopPropagation()
	}
	const handleClose = () => {
		setAnchorEl(null)
	}

	const dispatch = useDispatch()

	const deleteUserCheck = async (e) => {
		try {
			const res = await deleteCheck(deleteCheckId)

			dispatch(
				updateSnackbar({
					open: true,
					message: 'Record Deleted.',
					severity: 'success'
				})
			)
			getChecks(dispatch)
			closeModal()
		} catch (error) {
			dispatch(
				updateSnackbar({
					open: true,
					message: 'Unable to delete.',
					severity: 'error'
				})
			)
		}
	}

	const deleteUserCheckModal = async () => {
		setDeleteCheckNumber(rowData.checkNumber)
		setDeleteCheckStatus(rowData.status)
		setDeleteCheckId(rowData._id)
		openModal()
	}

	return (
		<>
			{isModalOpen && (
				<FormModalMUI
					onClose={closeModal}
					open={isModalOpen}
					maxWidth="sm"
					// onSave={onSubmit}
				>
					<div className="container">
						<div className="row">
							<div className="row txt-danger pt-3">
								<DeleteOutlineOutlined sx={{ fontSize: '80px' }} />
							</div>
							<div className="col d-flex justify-content-center">
								<div className="row">
									<h3>
										<p>
											<b>Confirm Deletion?</b>
										</p>
									</h3>
								</div>
							</div>
						</div>
						<div className="row">
							<div className="col d-flex justify-content-center">
								<div className="row text-center">
									<p>
										Are you sure you want to delete check no:{' '}
										<b>{deleteCheckNumber}</b> which is{' '}
										<b>{deleteCheckStatus}</b>?
									</p>
								</div>
							</div>
						</div>
					</div>

					<div className="d-flex align-items-center justify-content-center mt-3 mb-5">
						<ButtonComponent
							text="Cancel"
							type="button"
							variant="light"
							click={(event) => {
								closeModal()
								event.stopPropagation()
							}}
							extraClass="me-3"
						/>
						<ButtonComponent
							text="Delete"
							type="submit"
							variant="danger"
							click={(event) => {
								deleteUserCheck()
								event.stopPropagation()
							}}
						/>
					</div>
				</FormModalMUI>
			)}
			<IconButton
				aria-controls={open ? 'long-menu' : undefined}
				aria-expanded={open ? 'true' : undefined}
				aria-haspopup="true"
				onClick={(event) => {
					handleClick(event)
					event.stopPropagation()
				}}
				sx={{
					padding: '3px'
				}}
			>
				<MoreVert />
			</IconButton>
			<Menu
				id="long-menu"
				MenuListProps={{
					'aria-labelledby': 'long-button'
				}}
				anchorEl={anchorEl}
				open={open}
				onClose={(event) => {
					handleClose()
					event.stopPropagation()
				}}
				sx={{
					display: 'flex',
					justifyContent: 'start',
					alignItems: 'center'
				}}
			>
				{status &&
					!['EMAILED', 'CLEARED', 'VOID', 'BLANK', 'PRINTED'].includes(
						status
					) && (
						<MenuItem
							onClick={(event) => {
								handleClose()
								history.push({
									pathname: '/dashboard/create-check',
									state: {
										printContent: { ...rowData },
										printCheck: false,
										isUpdate: true
									}
								})
							}}
						>
							<EditOutlined
								sx={{
									marginRight: '20px'
								}}
							/>
							Edit
						</MenuItem>
					)}

				<MenuItem
					onClick={(e) => {
						handleClose()
						deleteUserCheckModal()
						e.stopPropagation()
					}}
				>
					<DeleteOutlineOutlined
						sx={{
							marginRight: '20px'
						}}
					/>
					Delete
				</MenuItem>
				<MenuItem
					onClick={(e) => {
						handleClose()
                        console.log({...rowData})
						// setPrintContent({ ...rowData })
						console.log(organizationData1)
						if (organizationData1) {
							// Update address values based on organizationData1
							const updatedRowData = {
								...rowData,
								address: {
									name1: organizationData1.organizationName || rowData.address.name,
									companyName: organizationData1.companyName || rowData.address.companyName,
									addressLine1: organizationData1.addressLine1 || rowData.address.addressLine1,
									addressLine2: organizationData1.addressLine2 || rowData.address.addressLine2,
									city: organizationData1.city || rowData.address.city,
									state: organizationData1.state || rowData.address.state,
									country: organizationData1.country || rowData.address.country,
									zipCode: organizationData1.zip || rowData.address.zipCode
								}
							};
							setPrintContent(updatedRowData);
						} else {
							setPrintContent({ ...rowData });
						}
						e.stopPropagation()
					}}
				>
					<PrintOutlined
						sx={{
							marginRight: '20px'
						}}
					/>
					Print
				</MenuItem>
			</Menu>
		</>
	)
}

const MyChecks = () => {
	const history = useHistory()
	let checks = useSelector((state) => state.appData.checks)
	const { status } = useParams()
	const [selectedStatus, setSelectedStatus] = useState(status)

	useEffect(() => {
		setSelectedStatus(status)
	}, [status])

	const [title, setTitle] = useState('')

	const tagData = useSelector((state) => state.appData.tags)

	const [printContent, setPrintContent] = useState(null)

	const printRef = useRef(null)

	const [isLoading, setIsLoading] = useState(true)

	const dispatch = useDispatch()

	const [modifiedData, setModifiedData] = useState([])

	const [selectedRows, setSelectedRows] = useState([])
	const [isMailModalOpen, setMailModalOpen] = useState(false)
	const [isEmailModalOpen, setEmailModalOpen] = useState(false)
	const [filteredData, setFilteredData] = useState([])
	const [voidConfirmModal, setVoidConfirmModal] = useState(false)
	const [clearedConfirmModal, setClearedConfirmModal] = useState(false)

	useEffect(() => {
		if (selectedStatus === 'Cleared') {
			setTitle('Cleared checks')
		} else if (selectedStatus === 'Uncleared') {
			setTitle('Uncleared checks')
		} else if (selectedStatus === 'Drafts') {
			setTitle('Draft checks')
		} else {
			setTitle('All checks')
		}

		if (checks.data.length !== 0) {
			setIsLoading(true)
			let data = checks.data.filter((item) => {
				if (selectedStatus === 'Cleared') {
					return item?.status === 'CLEARED'
				} else if (selectedStatus === 'Uncleared') {
					return item?.status === 'UNCLEARED'
				} else if (selectedStatus === 'Drafts') {
					return item?.status === 'DRAFT'
				} else {
					return true
				}
			})
			makeTableData(data)
		} else {
			setIsLoading(checks.isLoading)
			setModifiedData([])
		}
	}, [checks, selectedRows, selectedStatus, tagData])

	useEffect(() => {
		if (printContent && Object.keys(printContent).length !== 0) {
			handlePrint()
		}
	}, [printContent])

	const expandedColumnData = [
		{
			key: 'Bank Name',
			value: 'bankName'
		},
		{
			key: 'Bank Account No.',
			value: 'bankAccountNo'
		},
		{
			key: 'Invoice Id',
			value: 'invoiceId'
		},
		{
			key: 'Memo',
			value: 'memo'
		},
		{
			key: 'Description',
			value: 'description'
		}
	]

	const columnData = [
		{
			key: 'checkbox',
			value: 'sno',
			colWidth: '2%',
			align: 'right',
			type: 'html',
			isFilter: false
		},
		{
			key: 'Check No.',
			value: 'checkNumber',
			colWidth: '10%',
			align: 'center',
			isSort: true,
			sortType: 'number',
			isFilter: false
		},
		{
			key: 'Status',
			type: 'html',
			value: 'status',
			colWidth: '10%',
			align: 'center',
			isFilter: true,
			filterType: 'option'
		},
		{
			key: 'Amount',
			value: 'amount',
			colWidth: '10%',
			align: 'center',
			isSort: true,
			sortType: 'number',
			isFilter: true,
			filterType: 'number'
		},
		{
			key: 'Payee Name',
			value: 'payeeName',
			colWidth: '10%',
			align: 'center',
			isFilter: true,
			filterType: 'option'
		},
		{
			key: 'Issued date',
			value: 'issuedDate',
			colWidth: '13%',
			align: 'center',
			isSort: true,
			sortType: 'date',
			isFilter: true,
			filterType: 'date'
		},
		{
			key: 'Account NickName',
			value: 'bankNickname',
			colWidth: '12%',
			align: 'center',
			isFilter: true,
			filterType: 'option'
		},
		{
			key: 'Tags',
			value: 'tags',
			colWidth: '12%',
			type: 'html',
			align: 'center',
			isFilter: false
			// filterType: 'option'
		},
		{
			key: 'Actions',
			value: 'contextMenu',
			colWidth: '8%',
			type: 'contextmenu',
			align: 'center'
		}
	]

	const handlePrint = useReactToPrint({
		content: () => printRef.current,
		pageStyle: printStyles,
		documentTitle: `Check Number - ${printContent?.checkNumber}.pdf`,
		onAfterPrint: () => {
			if (
				printContent?.status !== 'VOID' &&
				printContent?.status !== 'CLEARED' &&
				printContent?.status !== 'BLANK' &&
				printContent?.status !== 'PRINTED'
			) {
				changeCheckstatus('PRINTED', printContent._id)
			}

			setPrintContent(null)
		}
	})

	const changeCheckstatus = async (checkStatus, id) => {
		try {
			const res = await updateCheck(
				{
					status: checkStatus
				},
				id
			)
			dispatch(
				updateSnackbar({
					open: true,
					message: 'check status updated.',
					severity: 'success'
				})
			)
			getChecks(dispatch)
		} catch (error) {
			dispatch(
				updateSnackbar({
					open: true,
					message: 'Status update fail.',
					severity: 'error'
				})
			)
		}
	}

	const doesSelectedIncludes = (arr, id) => {
		for (let i = 0; i < arr.length; i++) {
			if (arr[i]._id == id) {
				return true
			}
		}
		return false
	}

	const addRowToSelected = (row, rowId, comesFromStatus = false) => {
		if (comesFromStatus) {
			return false
		}
		let temp = [...selectedRows]

		if (!row?.payee?.email && row?.payee?.email === '') {
			dispatch(
				updateSnackbar({
					open: true,
					message: 'No email found in record.',
					severity: 'warning'
				})
			)
			return false
		}

		if (doesSelectedIncludes(temp, rowId)) {
			for (let i = 0; i < temp.length; i++) {
				if (temp[i]._id == row._id) {
					temp.splice(i, 1)
				}
			}
		} else {
			temp.push(row)
		}
		setSelectedRows(temp)
	}

	const selectRow = (checkId, bulk, event) => {
		if (bulk) {
			const rows = checks?.data?.filter((check) => {
				if (checkId.includes(check?._id)) {
					return checks?.data
				}
			})
			setSelectedRows(rows)
		} else {
			if (
				event.nativeEvent.srcElement?.getAttribute('data-bs-toggle') !==
					'dropdown' ||
				[...event?.nativeEvent?.srcElement?.classList]?.includes(
					'status-choices'
				)
			) {
				const selected = checks?.data?.find((check) => check?._id === checkId)
				addRowToSelected(selected, checkId)
			}
		}
	}

	const [changedCheckDetails, setChangedCheckDetails] = useState({})

	const openChangeConfirmModal = (checkStatus, checkId) => {
		switch (checkStatus) {
			case 'VOID':
				setVoidConfirmModal(true)
				setChangedCheckDetails({
					status: checkStatus,
					checkId: checkId
				})
				break
			case 'CLEARED':
				setClearedConfirmModal(true)
				setChangedCheckDetails({
					status: checkStatus,
					checkId: checkId
				})
		}
	}

	const confirmChecked = () => {
		changeCheckstatus(
			changedCheckDetails['status'],
			changedCheckDetails['checkId']
		)
		setClearedConfirmModal(false)
		getChecks(dispatch)
	}

	const confirmVoid = async () => {
		try {
			let id = changedCheckDetails['checkId']
			let body = {
				amount: 0
			}
			let res = await updateCheck(body, id)
			changeCheckstatus(changedCheckDetails['status'], id)
			setVoidConfirmModal(false)
			getChecks(dispatch)
			dispatch(
				updateSnackbar({
					open: true,
					severity: 'success',
					message: 'Status updated successfully'
				})
			)
		} catch (e) {
			dispatch(
				updateSnackbar({
					open: true,
					severity: 'error',
					message: 'Could not change status.'
				})
			)
		}
	}

	const makeTableData = (data) => {
		const temp = []

		if (data && data.length !== 0) {
			data.forEach((item, index) => {
				let obj = {}
				//let tags = fetchTags(item.tags)
				let date = new Date(item?.issuedDate)
				obj = {}
				obj.sno = (
					<Checkbox
						value={item._id}
						sx={{
							'&.MuiCheckbox-root': {
								padding: '8px',
								'&.Mui-checked': {
									color: '#5EA479'
								},
								'&:hover': {
									backgroundColor: '#5ea47929'
								},
								'& .MuiSvgIcon-root': {
									width: '18px',
									height: '18px'
								}
							}
						}}
						checked={
							selectedRows.length !== 0
								? selectedRows.some((row) =>
										row._id == item._id ? true : false
								  )
								: false
						}
						onClick={(e) => {
							addRowToSelected(item, item._id)
						}}
					/>
				)

				obj.bankNickname = item?.bankDetails?.accountNickName
				let allTags = item.tags
				let tags = []
				allTags.forEach((tagId) => {
					tags.push(...tagData.data.filter((item) => item['_id'] === tagId))
				})

				let number_of_tags = tags.length

				if (number_of_tags === 0) {
					obj.tags = { value: [], html: <></> }
				} else if (number_of_tags === 1) {
					let tag = tags[0]

					obj.tags = {
						value: [tags],
						html: (
							<Chip
								avatar={
									<span
										className="dot"
										style={{
											backgroundColor: tag.color,
											width: '9px',
											height: '9px'
										}}
									/>
								}
								label={tag.name}
								variant="outlined"
								size="small"
								sx={{
									padding: '6px 8px',
									border: '1px solid #EEEEEE',
									borderRadius: '6px',
									fontWeight: '500',
									fontSize: '12px',
									color: '#757575'
								}}
							/>
						)
					}
				} else {
					obj.tags = {
						value: [tags],
						html: (
							<Tooltip
								PopperProps={{
									className: 'check-tooltip-popper'
								}}
								title={
									<>
										{tags.map((tag) => (
											<Chip
												avatar={
													<span
														className="dot"
														style={{
															backgroundColor: tag.color,
															width: '9px',
															height: '9px'
														}}
													/>
												}
												label={tag.name}
												variant="outlined"
												size="small"
												sx={{
													border: '1px solid #EEEEEE',
													borderRadius: '6px',
													fontWeight: '500',
													fontSize: '12px',
													color: '#757575',
													display: 'flex',
													alignItems: 'center',
													justifyContent: 'center',
													padding: '6px 8px',
													height: 'auto',
													margin: '5px'
												}}
											/>
										))}
									</>
								}
							>
								<Chip
									avatar={
										<>
											{tags.map((tag) => (
												<span
													className="dot_bigger_overlap"
													style={{ backgroundColor: tag.color }}
												/>
											))}
										</>
									}
									label={number_of_tags + ' tags'}
									variant="outlined"
									size="small"
									sx={{
										padding: '6px 8px',
										border: '1px solid #EEEEEE',
										borderRadius: '6px',
										fontWeight: '500',
										fontSize: '12px',
										color: '#757575'
									}}
								/>
							</Tooltip>
						)
					}
				}
				obj.payeeName = item?.payee?.name
				obj.checkNumber = item.checkNumber
				obj.status = {
					value: item.status,
					html: (
						<CheckStatusPopover
							checkStatus={item?.status}
							checkId={item?._id}
							func={changeCheckstatus}
							openChangeConfirmModal={openChangeConfirmModal}
						/>
					)
				}
				obj.issuedDate = `${
					date.getMonth() + 1
				}/${date.getDate()}/${date.getFullYear()}`

				obj.amount =
					// item?.amount && item.amount - Math.floor(item.amount) !== 0
					// 	? `$${item.amount}`
					// 	: `$${item.amount}.00`
					item?.amount ? 
                (item.amount - Math.floor(item.amount) !== 0 ? `$${item.amount}` : `$${item.amount}.00`) 
                : '';

				obj.contextMenu = (
					<>
						<ContextMenu
							rowData={item}
							setPrintContent={setPrintContent}
							status={item.status}
						/>
					</>
				)
				obj.memo = item?.memo
				obj.invoiceId = item?.invoiceId
				obj.bankName = item?.bankDetails?.bankName
				obj.bankAccountNo = item?.bankDetails?.accountNumber
				obj.description = item?.description
				obj._id = item._id
				temp.push(obj)
			})

			setModifiedData([...temp])
			setFilteredData([...temp])
			setIsLoading(false)
		} else {
			setFilteredData([])
			setModifiedData([])
			setIsLoading(false)
		}
	}

	const openMailModal = () => {
		setMailModalOpen(true)
	}

	const closeMailModal = async () => {
		setMailModalOpen(false)
		setSelectedRows([])
		await getChecks(dispatch)
	}

	const openEmailModal = () => {
		setEmailModalOpen(true)
	}

	const closeEmailModal = () => {
		
		setEmailModalOpen(false)
	}
	// console.log(printContent)

	return (
		<div className="container">
			<div className="page-header d-flex align-items-center justify-content-between mb-4 pb-4">
				<div className="d-flex align-items-center justify-content-start">
					<h3
						className="fs-5 fw-semibold m-0"
						style={{
							color: '#12221fbf'
						}}
					>
						My Checks /
					</h3>
					<Select
						sx={{
							boxShadow: 'none',
							'.MuiOutlinedInput-notchedOutline': { border: 0 },
							'&.Mui-focused .MuiOutlinedInput-notchedOutline': {
								borderColor: 'white'
							},
							'& .MuiSelect-select': {
								paddingTop: '10px',
								paddingBottom: '10px',
								display: 'flex',
								alignItems: 'center'
							}
						}}
						MenuProps={{
							sx: {
								'&& .Mui-selected': {
									backgroundColor: '#EAFBE7'
								},
								'&& .Mui-focused': {
									backgroundColor: '#FFF8DE'
								}
							}
						}}
						value={title}
						onChange={(e) => {
							let val = e.target.value
							switch (val) {
								case 'All checks':
									history.push('/dashboard/my-checks/Totalchecks')
									break
								case 'Cleared checks':
									history.push('/dashboard/my-checks/Cleared')
									break
								case 'Uncleared checks':
									history.push('/dashboard/my-checks/Uncleared')
									break
								case 'Draft checks':
									history.push('/dashboard/my-checks/Drafts')
									break
							}
						}}
					>
						<MenuItem value={'All checks'} className="menu-item">
							<h3
								className="fs-6 fw-normal m-0"
								style={{
									color: '#12221fbf'
								}}
							>
								All checks
							</h3>
						</MenuItem>
						<MenuItem
							value={'Cleared checks'}
							defaultChecked
							className="menu-item"
						>
							<h3
								className="fs-6 fw-normal m-0"
								style={{
									color: '#12221fbf'
								}}
							>
								Cleared checks
							</h3>
						</MenuItem>
						<MenuItem
							value={'Uncleared checks'}
							defaultChecked
							className="menu-item"
						>
							<h3
								className="fs-6 fw-normal m-0"
								style={{
									color: '#12221fbf'
								}}
							>
								Uncleared checks
							</h3>
						</MenuItem>
						<MenuItem
							value={'Draft checks'}
							defaultChecked
							className="menu-item"
						>
							<h3
								className="fs-6 fw-normal m-0"
								style={{
									color: '#12221fbf'
								}}
							>
								Draft checks
							</h3>
						</MenuItem>
					</Select>
				</div>

				<Tooltip
					title={
						<span>
							Please contact at{' '}
							<a
								href="mailto:support@synccos.com"
								target="blank"
								rel="no-referrer"
								className="fw-semibold text-white"
							>
								support@synccos.com
							</a>{' '}
							for queries and support
						</span>
					}
				>
					<HelpOutlineOutlined />
				</Tooltip>
			</div>
			<div className=" d-flex align-items-center justify-content-between mt-4">
				<p className="regular-txt fs-14 mb-0">
					Below is the list of all your checks.
				</p>
				<div className="d-flex align-items-center justify-content-center">
					<ButtonComponent
						text="Mail"
						variant="light"
						icon={<MailOutline />}
						type="button"
						onClick={openMailModal}
						extraClass="me-3"
						disabled={selectedRows.length === 0 ? true : false}
					/>
					<ButtonComponent
						text="Email"
						variant="light"
						icon={<AlternateEmailOutlined />}
						type="button"
						onClick={openEmailModal}
						extraClass="me-3"
						disabled={selectedRows.length === 0 ? true : false}
					/>
					<ButtonComponent
						text="New"
						variant="dark"
						icon={<AddOutlined />}
						type="button"
						click={() => {
							history.push('/dashboard/create-check')
						}}
					/>
				</div>
			</div>
			<div className="generic-table-container">
				<GenericTable
					columnData={columnData}
					expandedColumnData={expandedColumnData}
					modifiedData={modifiedData}
					isLoading={isLoading}
					initialfilter={''}
					clickable={true}
					selectRow={selectRow}
					selectedRows={selectedRows}
					filteredData={filteredData}
					setFilteredData={setFilteredData}
					noDataProps={{
						text: 'All the checks created will be stored here. Create your first check!',
						head: 'No checks',
						click: () => {
							history.push('/dashboard/create-check')
						},
						btnText: 'New check'
					}}
				/>
			</div>
			{printContent && Object.keys(printContent).length !== 0 ? (
				<Printcheck
					printContent={printContent}
					showPreview={false}
					printRef={printRef}
					handlePrint={handlePrint}
				/>
			) : null}

			<FormModalMUI
				title={'Mail ' + selectedRows.length + ' checks'}
				open={isMailModalOpen}
				maxWidth="md"
				onClose={closeMailModal}
			>
				<MailChecks selectedData={selectedRows} onClose={closeMailModal} />
			</FormModalMUI>

			<FormModalMUI
				title={
					selectedRows.length === 0
						? 'No Emails Selected'
						: 'Email ' + selectedRows.length + ' checks'
				}
				open={isEmailModalOpen}
				maxWidth={selectedRows.length === 0 ? 'sm' : 'md'}
				onClose={closeEmailModal}
			>
				<EmailChecks
					selectedData={selectedRows}
					onClose={closeEmailModal}
					setSelectedRows={setSelectedRows}
				/>
			</FormModalMUI>

			<FormModalMUI
				open={voidConfirmModal}
				onClose={() => {
					setVoidConfirmModal(false)
				}}
				maxWidth="sm"
			>
				<div class="container" style={{ width: '450px' }}>
					<div class="row">
						<div class="d-flex align-items-center justify-content-center txt-danger mt-3 mb-2">
							<BlockOutlined sx={{ fontSize: '80px' }} />
						</div>
						<div class="col d-flex justify-content-center">
							<div class="row">
								<h3>
									<p>
										<b>Confirm Void?</b>
									</p>
								</h3>
							</div>
						</div>
					</div>
					<div class="row">
						<div class="col d-flex justify-content-center">
							<div class="row text-center">
								<p>This will make the amount in this check 0.</p>
							</div>
						</div>
					</div>
				</div>

				<div className="d-flex align-items-center justify-content-center mt-3 mb-4">
					<ButtonComponent
						text="Cancel"
						type="button"
						variant="light"
						click={() => {
							setVoidConfirmModal(false)
						}}
						extraClass="me-3"
					/>
					<ButtonComponent
						text={'Confirm'}
						type="submit"
						variant="danger"
						click={confirmVoid}
					/>
				</div>
			</FormModalMUI>

			<FormModalMUI
				open={clearedConfirmModal}
				onClose={() => {
					setClearedConfirmModal(false)
				}}
				maxWidth="sm"
			>
				<div class="container" style={{ width: '450px' }}>
					<div class="row">
						<div class="d-flex align-items-center justify-content-center text-success mt-3 mb-2">
							<DoneRounded sx={{ fontSize: '80px' }} />
						</div>
						<div class="col d-flex justify-content-center">
							<div class="row">
								<h3>
									<p>
										<b>Confirm Cleared?</b>
									</p>
								</h3>
							</div>
						</div>
					</div>
					<div class="row">
						<div class="col d-flex justify-content-center">
							<div class="row text-center">
								<p>
									Checks status once changed to 'Cleared' cannot be changed.
								</p>
							</div>
						</div>
					</div>
				</div>

				<div className="d-flex align-items-center justify-content-center mt-3 mb-4">
					<ButtonComponent
						text="Cancel"
						type="button"
						variant="light"
						click={() => {
							setClearedConfirmModal(false)
						}}
						extraClass="me-3"
					/>
					<ButtonComponent
						text={'Confirm'}
						type="submit"
						variant="dark"
						click={confirmChecked}
					/>
				</div>
			</FormModalMUI>
		</div>
	)
}

export default MyChecks
