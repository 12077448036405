import { useEffect, useRef, useState } from 'react'
import GenericTable from '../../shared/GenericTable/GenericTable'
import { useDispatch, useSelector } from 'react-redux'
import ButtonComponent from '../../shared/ButtonComponent'
import { postMailRate } from '../../../API/MailAPI'
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined'
import { IconButton } from '@mui/material'
import { updateSnackbar } from '../../../redux/snackbarState'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'
import FormComponents from '../../shared/forms'
import { jsPDF } from 'jspdf'
import Printcheck from '../Printcheck'
import { useReactToPrint } from 'react-to-print'
import { emailCheckPrintStyles, getCheckPrintContent, htmlToPdf, printStyles } from '../../../utils/helper'
import { sendCheckEmails } from '../../../API/EmailAPI'
import html2canvas from 'html2canvas'
import ReactDOMServer from 'react-dom/server'
import axios from 'axios'

const MailChecks = ({ selectedData, onClose }) => {
	const dispatch = useDispatch()
	console.log(selectedData, 'selectedData')
	const printRef = useRef(null)
	const pdfResolveRef = useRef(null);

	// const selectedData =[{
	// 	_id: '1',
	// 	checkNumber: '67890',
	// 	payee: { name: 'Jane Smith', address: { addressLine1: '456 Elm St', addressLine2: '', city: 'Othertown', state: 'NY', country: 'USA', zipCode: '67890' } },
	// 	rateId: 'rate2',
	// 	cost: 10
	// },{
	// 	_id: '2',
	// 	checkNumber: '67890',
	// 	payee: { name: 'Jane Smith', address: { addressLine1: '456 Elm St', addressLine2: '', city: 'Othertown', state: 'NY', country: 'USA', zipCode: '67890' } },
	// 	rateId: 'rate2',
	// 	cost: 10
	// }];
	const userData = useSelector((state) => state.appData.userData)
	const [signImagestr, setSignImageStr] = useState('')
	const [modifiedData, setModifiedData] = useState([])
	const [isLoading, setIsLoading] = useState(true)
	const [totalCost, setTotalCost] = useState(0)
	const [isDisabled, setIsDisabled] = useState(true)
	const [rateInfo, setRateInfo] = useState([])
	const [mailTo, setMailTo] = useState('')
	const [printContent, setPrintContent] = useState()
	const [mailAddress, setMailAddress] = useState('')
	const [isFormVisible, setIsFormVisible] = useState(false) // Manage form visibility
	const [selectedShipping, setSelectedShipping] = useState('PriorityMail') // Step 1: Create state

	useEffect(() => {
		if (selectedData.length !== 0) {
			setIsLoading(true)
			// createShipment(selectedData)
			makeTableData(selectedData)
		} else {
			setIsLoading(false)
		}
	}, [])

	useEffect(() => {
		if (rateInfo.length !== 0) {
			makeTableData(selectedData)
		}
	}, [])

	const validationSchema = Yup.object({
		// mailto: Yup.string().required('Required Mail To !'),
		// email: Yup.string().required('Required Email Address!'),
		shipping: Yup.string().required('Required Email Address!')
	})

	const createShipment = async (data) => {
		let shipments = []
		for (let item of data) {
			shipments.push({
				// payeeId: item?.payee?._id,
				checkId: item?._id,
				checkNumber: item?.checkNumber
			})
		}

		let body = {
			shipments: shipments
		}

		try {
			let res = await postMailRate(body)
			setRateInfo([...res?.data])
		} catch (error) {
			dispatch(
				updateSnackbar({
					open: true,
					severity: 'error',
					message: 'Unable to get rates. Try Again.'
				})
			)
		}
	}

	const removeItem = (id) => {
		console.log(id, 'Id Is Here')
		selectedData = selectedData.filter((item) => item._id !== id)
		console.log(selectedData, 'selectedDataof Remove')
		makeTableData(selectedData)
	}

	const makeTableData = (data) => {
		// console.log(data,"Data Is Here")
		let sno = 1
		let temp = []
		let total = 0

		for (let i = 0; i < data.length; i++) {
			let item = data[i]
			console.log('rateInfo', rateInfo)
			let cost = parseFloat(1.2 * rateInfo[i]?.rates[0]?.amount).toFixed(2)

			let rateId = rateInfo[i]?.rates[0]?.object_id
			total = parseFloat(total) + parseFloat(item.amount)

			temp.push({
				_id: item?._id,
				sno: sno,
				checkNo: item?.checkNumber,
				payeeName: item?.payee?.name,
				address:
					item?.payee?.address?.addressLine1 +
					', ' +
					item?.payee?.address?.addressLine2 +
					', ' +
					item?.payee?.address?.city +
					', ' +
					item?.payee?.address?.state +
					', ' +
					item?.payee?.address?.country +
					', ' +
					item?.payee?.address?.zipCode,
				cost: '$ ' + item.amount,
				rateId: rateId,
				remove: (
					<IconButton color="error" onClick={() => removeItem(item?._id)}>
						<DeleteOutlinedIcon />
					</IconButton>
				)
			})

			setTotalCost(total)
			// console.log(temp)
			setModifiedData(temp)
			sno += 1
		}

		setIsLoading(false)
		setIsDisabled(false)
	}

	const sendMail = async () => {
		let checksDetails = []

		for (let details of modifiedData) {
			checksDetails.push({
				checkId: details._id,
				checkNumber: details.checkNo,
				payeeName: details.payeeName,
				rateId: details.rateId,
				totalAmount: details.cost.substring(2)
			})
		}

		let body = {
			checksDetails: checksDetails
		}

		// let res = await postPayments(body)
		// let payment_url = res.data
		// window.location.replace(payment_url)
	}

	const columnData = [
		{
			key: '#',
			value: 'sno',
			colWidth: '2%',
			align: 'left'
		},
		{
			key: '# Check No',
			value: 'checkNo',
			colWidth: '2%',
			align: 'left'
		},
		{
			key: 'Payee Name',
			value: 'payeeName',
			colWidth: '2%',
			align: 'left'
		},
		{
			key: 'Address',
			value: 'address',
			colWidth: '4%',
			align: 'center'
		},
		{
			key: 'Amount',
			value: 'cost',
			colWidth: '2%',
			align: 'right'
		},
		{
			key: 'Remove',
			value: 'remove',
			type: 'html',
			colWidth: '2%',
			align: 'center'
		}
	]

	const onSubmit = (value) => {
		// e.preventDefault();
		console.log(value)
		// const newEntry = {
		// 	_id: Date.now().toString(), // Unique ID for the new entry
		// 	checkNumber: mailTo, // Using mailTo as checkNumber for demonstration
		// 	payeeName: mailAddress, // Using mailAddress as payeeName for demonstration
		// 	amount: 0, // Set a default amount or modify as needed
		// };
		// setModifiedData((prevData) => [...prevData, newEntry]);
		// setMailTo(''); // Clear input
		// setMailAddress(''); // Clear input
		// setIsFormVisible(false); // Hide the form after submission
	}

	const close = (forced) => {
		setIsFormVisible(false)
	}

	// const printContent = {
	// 	_id: '66b4c2759d88629d87241c41',
	// 	status: 'PRINTED',
	// 	userId: '6620bffaa11f58bdd55f07ce',
	// 	checkNumber: 64,
	// 	invoiceId: '23',
	// 	amount: 12,
	// 	createdDate: '2024-08-08T00:00:00.000Z',
	// 	issuedDate: '2024-08-08T00:00:00.000Z',
	// 	payee: {
	// 		name: 'Leon',
	// 		nickName: null,
	// 		companyName: 'Leon',
	// 		address: {
	// 			name: 'Leon',
	// 			companyName: null,
	// 			addressLine1: 'Test',
	// 			addressLine2: 'test',
	// 			city: 'Aguanga',
	// 			state: 'California',
	// 			country: 'United States',
	// 			zipCode: 10001,
	// 			_id: '662965ec7e5d6ca36430996c'
	// 		},
	// 		phone: '1123456789',
	// 		email: 'email4@testreview.xyz',
	// 		_id: '662965ec7e5d6ca36430996b'
	// 	},
	// 	bankDetails: {
	// 		bankName: '1st Source Bank',
	// 		accountType: 'SAVINGS',
	// 		accountName: 'Leon Dev',
	// 		accountNickName: 'Dev',
	// 		accountNumber: 12345678,
	// 		bankRoutingNumber: '1234',
	// 		bankTransitNumber: null,
	// 		financialInstituteNumber: null,
	// 		country: 'USA',
	// 		bankPreferences: {
	// 			checkNumbers: {
	// 				2: {
	// 					_id: '664217957e5d6ca36430ad83'
	// 				},
	// 				3: {
	// 					_id: '664218b37e5d6ca36430adfc'
	// 				},
	// 				4: {
	// 					_id: '664341e87e5d6ca36430b027'
	// 				},
	// 				5: {
	// 					_id: '6643423a7e5d6ca36430b064'
	// 				},
	// 				13: {
	// 					_id: '664f26577e5d6ca36430ed17'
	// 				},
	// 				16: {
	// 					_id: '66508cac7e5d6ca3643108c3'
	// 				},
	// 				17: {
	// 					_id: '665615b27e5d6ca364310c33'
	// 				},
	// 				18: {
	// 					_id: '665854e77e5d6ca364310d52'
	// 				},
	// 				19: {
	// 					_id: '66585b448eb074f221459d3a'
	// 				},
	// 				20: {
	// 					_id: '66585ffd61b94a87c3e56b69'
	// 				},
	// 				21: {
	// 					_id: '6658600061b94a87c3e56b89'
	// 				},
	// 				22: {
	// 					_id: '6658606061b94a87c3e56ba9'
	// 				},
	// 				23: {
	// 					_id: '665dc54fa4eb2f5124a84d0e'
	// 				},
	// 				24: {
	// 					_id: '665dc54fa4eb2f5124a84d31'
	// 				},
	// 				25: {
	// 					_id: '665dc58fa4eb2f5124a84d88'
	// 				},
	// 				26: {
	// 					_id: '665dc58fa4eb2f5124a84dac'
	// 				},
	// 				27: {
	// 					_id: '665dc990a4eb2f5124a84f4f'
	// 				},
	// 				28: {
	// 					_id: '665dc990a4eb2f5124a84f75'
	// 				},
	// 				29: {
	// 					_id: '665dc9abea70488e74b4b25b'
	// 				},
	// 				30: {
	// 					_id: '666854f1c4bedd9b439c6f04'
	// 				},
	// 				31: {
	// 					_id: '666855a4c4bedd9b439c704a'
	// 				},
	// 				32: {
	// 					_id: '666855a4c4bedd9b439c7074'
	// 				},
	// 				33: {
	// 					_id: '6668590cc4bedd9b439c718f'
	// 				},
	// 				34: {
	// 					_id: '6668590cc4bedd9b439c71bb'
	// 				},
	// 				35: {
	// 					_id: '66685fc1f21c0b2332e0924f'
	// 				},
	// 				36: {
	// 					_id: '66685fc1f21c0b2332e09257'
	// 				},
	// 				38: {
	// 					_id: '6668616d9d83a8a2ce1c4491'
	// 				},
	// 				39: {
	// 					_id: '6668616d9d83a8a2ce1c4499'
	// 				},
	// 				40: {
	// 					_id: '666864889fd6c22b486d14fc'
	// 				},
	// 				41: {
	// 					_id: '666864889fd6c22b486d1504'
	// 				},
	// 				42: {
	// 					_id: '666864889fd6c22b486d150c'
	// 				},
	// 				43: {
	// 					_id: '6668649a9fd6c22b486d1595'
	// 				},
	// 				44: {
	// 					_id: '6668649a9fd6c22b486d15ca'
	// 				},
	// 				45: {
	// 					_id: '666864ba9fd6c22b486d1684'
	// 				},
	// 				46: {
	// 					_id: '666864ba9fd6c22b486d16bb'
	// 				},
	// 				47: {
	// 					_id: '666864ba9fd6c22b486d16f3'
	// 				},
	// 				48: {
	// 					_id: '666864cf9fd6c22b486d1796'
	// 				},
	// 				49: {
	// 					_id: '666864cf9fd6c22b486d17d0'
	// 				},
	// 				50: {
	// 					_id: '666864dd9fd6c22b486d183e'
	// 				},
	// 				51: {
	// 					_id: '666864dd9fd6c22b486d1846'
	// 				},
	// 				52: {
	// 					_id: '666864dd9fd6c22b486d184e'
	// 				},
	// 				53: {
	// 					_id: '666864dd9fd6c22b486d1856'
	// 				},
	// 				54: {
	// 					_id: '668e93a57aeaa5f8768d415b'
	// 				},
	// 				55: {
	// 					_id: '668e93c67aeaa5f8768d41f3'
	// 				},
	// 				56: {
	// 					_id: '669789209d88629d8723b3df'
	// 				},
	// 				57: {
	// 					_id: '669789fb9d88629d8723b52d'
	// 				},
	// 				58: {
	// 					_id: '66978a249d88629d8723b647'
	// 				},
	// 				59: {
	// 					_id: '66978a249d88629d8723b6d5'
	// 				},
	// 				60: {
	// 					_id: '66978aeb9d88629d8723b870'
	// 				},
	// 				61: {
	// 					_id: '66978aeb9d88629d8723b902'
	// 				},
	// 				62: {
	// 					_id: '669a252d9d88629d8723d69a'
	// 				},
	// 				63: {
	// 					_id: '66b4672c9d88629d872413d5'
	// 				}
	// 			},
	// 			defaultCheckNumberLength: 6,
	// 			defaultCheckStartNumber: 1,
	// 			lastUsedCheckNumber: 63,
	// 			_id: '6620c050a11f58bdd55f07e6'
	// 		},
	// 		balance: 41,
	// 		_id: '6620c050a11f58bdd55f07e5'
	// 	},
	// 	address: {
	// 		name: 'Work',
	// 		companyName: null,
	// 		addressLine1: 'Work',
	// 		addressLine2: null,
	// 		city: 'Agoura',
	// 		state: 'California',
	// 		country: 'United States',
	// 		zipCode: 10001,
	// 		_id: '662966137e5d6ca364309971'
	// 	},
	// 	tags: ['6620bffaa11f58bdd55f07d9'],
	// 	memo: '23',
	// 	description: null,
	// 	createdAtUnix: 1723122293,
	// 	updatedAtUnix: 1723122293,
	// 	pdfStored: false,
	// 	isSignatureSelected: false,
	// 	isBlankCheck: false,
	// 	organizationId: null
	// }

	const [pdfFiles, setPdfFiles] = useState([]) // State to hold generated PDFs

	const createPDFs = async (selectedData, data) => {
		const combinedData = new FormData();
		const blobArray = [];

		for (const item of selectedData) {
			console.log(item);
			let pdfBlob = await getPDFDesign(item); // Now this will wait for the PDF to be generated
			console.log(pdfBlob,'blobllingingign')
			const file = blobToFile(pdfBlob, `check_${item.checkNumber}.pdf`);
			blobArray.push(file);
		}

		console.log(blobArray, 'Files');

		blobArray.forEach((file) => {
			combinedData.append('blob', file);
		});

		combinedData.append('data', JSON.stringify(data));
		combinedData.append('shippingType', selectedShipping);

		console.log(combinedData, 'Combined Data Object');
		try {
			const res = await sendCheckEmails(combinedData); // Ensure this is awaited
			dispatch(
				updateSnackbar({
					open: true,
					severity: 'success',
					message: res.data.message
				})
			);
			onClose()
		} catch (err) {
			return false;
		}
	};

	const getPDFDesign = async (item) => {
		let pdfRef = getCheckPrintContent({
			...item,
			userData: userData,
			signImagestr
		})

		const jsxExp = ReactDOMServer.renderToString(pdfRef.current)

		const combinedHtml = `<style>${emailCheckPrintStyles}</style>${jsxExp}`
		let pdfBlob = await htmlToPdf({ html: combinedHtml, checkId: item._id })
		console.log(pdfBlob, 'ksjdgfksjgfjh');

		return pdfBlob;
	};


	const onNext = (value) => {
		createPDFs(selectedData, modifiedData)
	}

	const ShippingOptions = [
		{ key: 'Priority Mail', value: 'PriorityMail' },
		{ key: 'Ground Advantage', value: 'Ground Advantage' },
		{ key: 'Overnight', value: 'Overnight' },
		{ key: 'Express', value: 'Express' }
	]

	const viewPDF = (index) => {
		// const pdfBlob = pdfFiles[index];
		// const url = URL.createObjectURL(pdfBlob);
		// window.open(url); // Open the PDF in a new tab
		handlePrint()
	}

	const generatePDF = async () => {
		if (printRef.current) {
			console.log(printRef.current, 'kjgshfuysdfh');
			console.log('calalidfgindfgi')
			const canvas = await html2canvas(printRef.current, { useCORS: true, scale: 2 });
			const imgData = canvas.toDataURL('image/png');
			console.log(imgData, 'ajgshdkjafshk');

			const pdfWidth = 612; // 8.5 inches * 72
			const pdfHeight = 792; // 11 inches * 72

			const pdf = new jsPDF({
				orientation: 'portrait',
				unit: 'pt',
				format: [pdfWidth, pdfHeight],
			});

			const imgWidth = pdfWidth;
			const imgHeight = (canvas.height * imgWidth) / canvas.width;

			const marginY = (pdfHeight - imgHeight) / 2;

			pdf.addImage(imgData, 'PNG', 0, marginY, imgWidth, imgHeight);

			const pdfBlob = pdf.output('blob');

			console.log(pdfBlob, "dkfjgsdkfjh");

			// Reset print content if needed
			setPrintContent(null);

			// Resolve the promise
			if (pdfResolveRef.current) {
				pdfResolveRef.current(pdfBlob);
				pdfResolveRef.current = null; // Reset the ref for the next iteration
			}
		} else {
			console.error('printRef.current is not available');
		}
	};


	useEffect(() => {
		if (printContent && Object.keys(printContent).length !== 0) {
			generatePDF();
		}
	}, [printContent]);

	useEffect(() => {
		getImageBlob()
	}, [userData])

	const getImageBlob = () => {
		console.log('use4rdata signature url', userData?.data?.signatureUrl)
		axios
			.get(userData?.data?.signatureUrl, { responseType: 'blob' })
			.then((response) => {
				const imageBlob = response.data

				const reader = new FileReader()

				reader.onloadend = () => {
					const base64Image = reader.result.split(',')[1]

					setSignImageStr(base64Image)
				}

				reader.readAsDataURL(imageBlob)
			})
			.catch((err) => {
				getImageBlob()
			})
		// Convert the image blob to a base64 data URI
	}

	const handleButtonClick = (item) => {
		console.log(item,"allItem is here")
		setPrintContent(item)

		// console.log(item)
		// handlePrint()
	}

	const handlePrint = useReactToPrint({
		content: () => printRef.current,
		pageStyle: printStyles,
		documentTitle: `Check Number - ${printContent?.checkNumber}.pdf`,
		onBeforePrint: () => {
			// Create a Blob URL after printing
			const pdfBlob = new Blob([printRef.current], { type: 'application/pdf' })
			console.log(pdfBlob, 'blob Is here')
			const pdfUrl = URL.createObjectURL(pdfBlob)
			sendToBackend(pdfUrl)
			setPrintContent()
		}
	})

	const blobToFile = (theBlob, fileName) => {
		// A File is a Blob with a name property
		return new File([theBlob], fileName, { type: theBlob.type })
	}

	const sendToBackend = async (pdfBlob) => {
		const formData = new FormData()
		// formData.append('file', pdfBlob); // Append the Blob to FormData
		const file = blobToFile(pdfBlob, 'generated_pdf.pdf')

		console.log(file, 'files');
		console.log(pdfBlob, "pdfBlob");
		// const formData = new FormData();
		formData.append('pdfFile', pdfBlob)
		for (let [key, value] of formData.entries()) {
			console.log(`${key}:`, value)
		}

		console.log(formData, 'formdataasasasasas')
	}

	return (
		<div className="container" style={{ width: '820px' }}>
			<div className="generic-table-container">
				{!isFormVisible && (
					<GenericTable
						columnData={columnData}
						modifiedData={modifiedData}
						count={selectedData?.length || 0}
						// initialfilter={''}
						paginationOff={true}
					/>
				)}
				{!isFormVisible && (
					<Formik
						initialValues={{
							shipping: 'PriorityMail'
						}}
						validationSchema={validationSchema}
						onSubmit={onSubmit}
					>
						{({ handleSubmit, setFieldValue, values, dirty }) => (
							<Form onSubmit={handleSubmit} className="px-3 pt-3 pb-3">
								<div className="row">
									<div className="col-12 col-md-12">
										<div className="d-flex align-items-start">
											<div className="col-12 col-md-12">
												<FormComponents
													options={ShippingOptions}
													control="radio"
													name="shipping"
													label={
														<span style={{ fontSize: '1.2em' }}>Shipping</span>
													}
													onChange={(e) => setSelectedShipping(e.target.value)}
												/>
											</div>
										</div>
									</div>
								</div>

								{!isFormVisible && (
									<div className="d-flex flex-row align-items-start justify-content-end mt-2">
										<div className="mt-1">
											<ButtonComponent
												text="Submit"
												variant="dark"
												onClick={onNext}
												disabled={isDisabled}
											/>
										</div>
									</div>
								)}
							</Form>
						)}
					</Formik>
				)}
				{printContent && (
					<Printcheck
						printContent={printContent}
						showPreview={true}
						printRef={printRef}
						handlePrint={handlePrint}
					/>
				)}
			</div>
			{/* {pdfFiles.length > 0 && (
				<div className="d-flex flex-row align-items-start justify-content-start mt-2">
					{pdfFiles.map((_, index) => (
						<ButtonComponent
							key={index}
							text={`View PDF ${index + 1}`} // Button text
							variant="dark"
							onClick={() => viewPDF(pdfFiles)} // Call viewPDF with the index
						/>
					))}
				</div>
			)} */}
			{/*{selectedData.map((item) => (*/}
			{/*	<div className="d-flex flex-row align-items-start justify-content-start mt-2">*/}
			{/*		<ButtonComponent*/}
			{/*			key={item._id}*/}
			{/*			text={`Print ${item.checkNumber}`} // Button text*/}
			{/*			variant="dark"*/}
			{/*			onClick={() => handleButtonClick(item)} // Set print content on click*/}
			{/*		/>*/}
			{/*	</div>*/}
			{/*))}*/}
		</div>
	)
}

export default MailChecks
